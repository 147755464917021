import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./additional-funding.css"
import NonProfit from "../data/af-non-profits"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby"

const AdditionalFundingNonProfits = () => {
    const [nonProfit, setNonProfit] = useState(NonProfit.nonProfit)

  return (
    <Layout>
      <SEO title="Non-Profits" />
      <div>
        <div className="container primary-bg">
        <div>
          <Link className="additonalFundingHeader"  to="/additional-funding">Available Funding Sources</Link>
          </div>
        <div>
        <label className="additonalFundingTitle">Non-Profits</label>
        </div>
          <hr />
          <section className="container">
            {nonProfit
              .sort((a, b) => (a.FoundationName > b.FoundationName ? 1 : -1))
              .map((nonProfit, index) => {
                return (
                  <div className="card">
                      <label className="additonalFundingCardTitle">{nonProfit.FoundationName}</label>
                      <div>
                        <FontAwesomeIcon
                          className="iconStyle"
                          size="lg"
                          icon={faGlobe}
                        />
                        <a
                          className="additonalFunding"
                          href={nonProfit.link}
                          target="_blank"
                        >
                          {nonProfit.link}
                        </a>
                      </div>
                  </div>
                )
              })}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AdditionalFundingNonProfits
