export const AFNonProfit = {
  nonProfit: [
    {
      "FoundationName": "South Florida Regional Planning Council",
      "link":"http://sfregionalcouncil.org/portfolio-items/revolving-loan-fund-rlf/",
    },
    {
      "FoundationName": "Partners for Self-Employment",
      "link": "http://partnersforselfemployment.org/programs/",
    },
    {
      "FoundationName": "Accion",
      "link": "https://us.accion.org/",
    },
    {
      "FoundationName": "Opa-locka CDC",
      "link": "http://www.olcdc.org/",
    },
  ],
}

export default AFNonProfit
